import styles from './style.module.scss';
import { Decimal } from 'decimal.js';

import LabelField from '@components/LabelField';
import LabelFieldCheckbox from '@components/m/LabelFieldCheckbox';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MarginCalc from '@components/MarginCalc';
import RangeSlider from '@components/RangeSlider';

import Spring from '@components/Spring';

import { useThemeProvider } from '@contexts/themeContext';
import { useAuth } from '@contexts/AuthContext';
import { userContext } from '@contexts/userContext';
import { symbolsContext } from '@contexts/symbolsContext';
import { ordersContext } from '@contexts/ordersContext';

import { fetchGetSymbol, fetchCreateOrder } from '@services/apiService';

import graphLight from '@assets/icons/graphLight.svg';
import graphDark from '@assets/icons/graphDark.svg';
import CurrencyLabel from '@ui/CurrencyLabel';
import {toast} from 'react-toastify';

const TradeDetail = ({ symbol }) => {
  const { theme } = useThemeProvider();
  const { authToken } = useAuth();
  const { symbols, livePrice } = useContext(symbolsContext);
  const [symbolData, setSymbolData] = useState(null);
  const [livePriceData, setLivePriceData] = useState(null);
  const [calcSymbolData, setCalcSymbolData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Buy');
  const userProfile = useContext(userContext);
  const { refreshOrders } = useContext(ordersContext);
  const navigate = useNavigate();
  const [marketLimit, setMarketLimit] = useState(null);
  const [stopLoss, setStopLoss] = useState(null);
  const [takeProfit, setTakeProfit] = useState(null);

  useEffect(() => {
    if (symbols) {
      const foundSymbol = symbols.find(s => s.symbol === symbol);
      if (foundSymbol) {
        setSymbolData(foundSymbol);
        setAmount(new Decimal(foundSymbol.min).toFixed(2));
        if ([1, 2].includes(foundSymbol.calc)) {
          let calcSymbol = null;
          if (foundSymbol.calc === 1) {
            calcSymbol = 'USD' + foundSymbol.currencyProfit;
          } else if (foundSymbol.calc === 2) {
            calcSymbol = foundSymbol.currencyProfit + 'USD';
          }
          if (calcSymbol) {
            const foundCalcSymbol = symbols.find(s => s.symbol === calcSymbol);
            if (foundCalcSymbol) {
              setCalcSymbolData(foundCalcSymbol);
            }
          }
        }
      } else {
        navigate('/');
      }
    }
  }, [symbol, symbols, navigate]);

  useEffect(() => {
    if (livePrice && livePrice[symbol]) {
      const { ask, bid } = livePrice[symbol];
      setLivePriceData({ ask, bid });
    }
  }, [livePrice, symbol]);

  if (!symbolData || (symbolData.calc !== 0 && !calcSymbolData)) {
    return <div>Veri Bulunamadı</div>;
  }

  if (!userProfile) {
    return <div>Yükleniyor...</div>;
  }

  const minAmount = new Decimal(symbolData.min);
  const maxAmount = new Decimal(symbolData.max);

  const increaseAmount = () => {
    setAmount(prevAmount => {
      const updatedAmount = new Decimal(prevAmount).plus(minAmount);
      return updatedAmount.lte(maxAmount) ? updatedAmount.toFixed(2) : maxAmount.toFixed(2);
    });
  };

  const decreaseAmount = () => {
    setAmount(prevAmount => {
      const updatedAmount = new Decimal(prevAmount).minus(minAmount);
      return updatedAmount.gte(minAmount) ? updatedAmount.toFixed(2) : minAmount.toFixed(2);
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setAmount(minAmount.toFixed(2));
      return;
    }

    let safeValue = new Decimal(value);
    if (safeValue.isNaN()) {
      safeValue = new Decimal(minAmount);
    }
    const clampedValue = Decimal.max(minAmount, Decimal.min(safeValue, maxAmount)).toFixed(2);
    setAmount(clampedValue);
  };

  const handleCreateOrder = async () => {
    try {
      const formData = {
        symbol: symbolData.symbol,
        type: activeTab,
        amount: amount,
        marketLimit: marketLimit,
        stopLoss: stopLoss,
        takeProfit: takeProfit,
      };

      const response = await fetchCreateOrder(formData, authToken);
      if (response.status) {
        await refreshOrders('position');
        navigate('/');
        toast.success('Başarıyla Tamamlandı.', {
          autoClose: 3000
        });
      } else {
        toast.error(response.message, {
          autoClose: 3000
        });
      }
    } catch (error) {
      const errorMessage = error.message || 'Bir hata oluştu.';
      toast.error(errorMessage, {
        autoClose: 5000
      });
    }
  };

  const iconPath = `../../assets/symbols/${symbolData.symbol.toLowerCase()}.png`;

  return (
    <Spring className="widget">
      <div className="widget_body card" style={{ marginBottom: '150px' }}>
        <div className="d-flex flex-column g-5">
          <div className={styles.traderItem}>
            <div className={styles.traderImage}>
              <img src={iconPath} alt='' />
            </div>
            <div className="d-flex flex-column">
              <h2 className="text-overflow">{symbolData.symbol}</h2>
              <span>{symbolData.desc}</span>
            </div>
          </div>
          <div className={`${styles.tabList} btn-group btn-block base-TabsList-root base-TabsList-horizontal col-2 bordered`}>
            <span
              className={`${styles.sellTab} trade-tab ${activeTab === 'Sell' ? 'active' : ''}`}
              onClick={() => setActiveTab('Sell')}
              style={{ cursor: 'pointer', background: activeTab === 'Sell' ? 'rgb(242, 54, 69)' : '#b8b8b800', color: activeTab === 'Sell' ? 'white' : 'var(--text-dark)' }}>
              Satış <span style={{ float: 'right' }} className={`bid + ${symbolData.symbol}`} id="tradeSell">{livePriceData.bid}</span>
            </span>
            <span
              className={`${styles.buyTab} trade-tab ${activeTab === 'Buy' ? 'active' : ''}`}
              onClick={() => setActiveTab('Buy')}
              style={{ cursor: 'pointer', background: activeTab === 'Buy' ? 'rgb(9, 183, 109)' : '#b8b8b800', color: activeTab === 'Sell' ? 'var(--text-dark)' : 'white' }}>
              <span style={{ float: 'left' }} className={`ask + ${symbolData.symbol}`} id="tradeBuy">{livePriceData.ask}</span> Alış
            </span>
          </div>
          <div className="d-flex g-5" style={{ alignItems: 'flex-end' }}>
            <button
              className={`btn ${theme === 'light' ? 'btn--invert' : ''}`}
              onClick={decreaseAmount}
              style={{ width: '35px' }}
              disabled={amount <= minAmount}>
              -
            </button>
            <div style={{ flex: 1, margin: '0 10px' }}>
              <LabelField style={{ textAlign: 'center' }}
                id="amount"
                placeholder="Min. amount: 0.01"
                value={amount}
                onChange={handleChange} />
            </div>
            <button
              className={`btn ${theme === 'light' ? 'btn--invert' : ''}`}
              onClick={increaseAmount}
              style={{ width: '35px' }}
            >
              +
            </button>
          </div>
          <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', padding: '15px 5px 0px 5px' }}>
            <span>Min: {symbolData.min}</span>
            <span>Max: {symbolData.max}</span>
          </div>
          <RangeSlider value={amount} onChange={handleChange} min={minAmount} max={maxAmount} step="0.01" />
          <div>
            <LabelFieldCheckbox
              minAmount={minAmount}
              maxAmount={maxAmount}
              sellPrice={livePriceData.bid}
              buyPrice={livePriceData.ask}
              tab={activeTab}
              id="deneme"
              setMarketLimit={setMarketLimit}
              setStopLoss={setStopLoss}
              setTakeProfit={setTakeProfit}
            />
          </div>

        </div>
        <div className="d-flex flex-column g-8">
          <MarginCalc
            freeMargin={userProfile.marginFree}
            calcSymbol={calcSymbolData || {}}
            tab={activeTab}
            symbol={symbolData}
            ask={livePriceData.ask}
            bid={livePriceData.bid}
            amount={amount}
            amountcurrency='USD'
          />
        </div>
        {activeTab === 'Buy' && (
          <button className={`${styles.btnBuy} btn ${theme === 'dark' ? 'btn--invert' : ''}`}
            onClick={handleCreateOrder}
          >
            Buy
          </button>
        )}
        {activeTab === 'Sell' && (
          <button className={`${styles.btnSell} btn ${theme === 'light' ? 'btn--invert' : ''}`}
            onClick={handleCreateOrder}
          >
            Sell
          </button>
        )}
      </div>
    </Spring>
  );
}

export default TradeDetail;

